/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { isNotNil } from '@newfront-insurance/core';
import type { UseComboboxGetItemPropsOptions, UseComboboxGetToggleButtonPropsOptions } from 'downshift';
import type {
  ButtonHTMLAttributes,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react';
import * as React from 'react';
import { forwardRef } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';

import type { BaseSelectorProps, DisabledProps } from './types';
import { colors } from '../../../theme/colors';
import { cn } from '../../../v2';
import { IconButton } from '../../icon-button';
import { IconArrowDropDown, IconSearch2 } from '../../icons';
import { Popper } from '../../popper';
import { Spacing } from '../../spacing';
import { CircleSpinnerInline } from '../../spinner';
import { Tooltip } from '../../tooltip';

interface UnfocusedInputProps<T>
  extends Pick<
    BaseSelectorProps<T>,
    'isLoading' | 'emptyPlaceholder' | 'placeholder' | 'itemToString' | 'isClearable' | 'renderInputToolbar' | 'testId'
  > {
  getToggleButtonProps: (options: UseComboboxGetToggleButtonPropsOptions) => object;
  hasValue?: boolean;
  renderSelectedItem?: () => ReactNode;
  renderSelectedItemHover?: () => ReactNode;
  renderUnfocusedInputToolbar?: () => ReactNode;
  onClear: () => void;
  isOpen: boolean;
  testId?: string;
  withAIStyling?: boolean;
  disabled?: boolean;
  isCompact?: boolean;
}

export function UnfocusedInput<T extends object>({
  getToggleButtonProps,
  isLoading,
  emptyPlaceholder,
  renderUnfocusedInputToolbar,
  renderSelectedItem,
  renderSelectedItemHover,
  hasValue,
  isClearable,
  isOpen,
  onClear,
  testId,
  withAIStyling,
  disabled = false,
  isCompact = false,
}: UnfocusedInputProps<T>): JSX.Element {
  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
    interval: 300,
    timeout: 200,
    sensitivity: 10,
  });

  return (
    <div ref={hoverRef}>
      <Popper
        isOpen={isHovering}
        placement="bottom"
        trigger={({ triggerRef }) => (
          <SelectUnfocusedInput
            withAIStyling={withAIStyling}
            {...getToggleButtonProps({ tabIndex: 0, ref: triggerRef })}
            isClearable={!disabled && !!hasValue && isClearable && !isOpen}
            onClear={(e) => {
              e.stopPropagation();

              onClear();
            }}
            testId={testId}
            disabled={disabled}
            isCompact={isCompact}
          >
            {!hasValue ? (
              <>
                {isLoading && (
                  <>
                    <CircleSpinnerInline />
                    <Spacing width={8} />
                  </>
                )}

                <SelectPlaceholder>{isLoading ? 'Loading...' : emptyPlaceholder}</SelectPlaceholder>
              </>
            ) : (
              renderSelectedItem?.()
            )}

            {isOpen && <div className="ml-auto mr-2 flex">{renderUnfocusedInputToolbar?.()}</div>}
          </SelectUnfocusedInput>
        )}
      >
        {hasValue && renderSelectedItemHover?.()}
      </Popper>
    </div>
  );
}

interface SelectableItemProps<T>
  extends Pick<BaseSelectorProps<T>, 'renderItem' | 'renderItemHover' | 'getDisabledProps'> {
  getItemProps: (options: UseComboboxGetItemPropsOptions<T>) => object;
  item: T;
  isHovering: boolean;
  isSelected: boolean;
  index: number;
  renderItemHover?: (item: T) => ReactNode;
  hasScrollbarOffset?: boolean;
}

export function SelectableItem<T>({
  getItemProps,
  renderItem,
  renderItemHover,
  item,
  isHovering,
  isSelected,
  index,
  hasScrollbarOffset,
  getDisabledProps,
}: SelectableItemProps<T>): JSX.Element {
  const disabled = getDisabledProps?.(item, { index });

  return (
    <div
      {...getItemProps({
        item,
        index,
      })}
    >
      <Popper
        portalElement={document.body}
        offsetDistance={hasScrollbarOffset ? 24 : 12}
        isOpen={!!renderItemHover && isHovering}
        placement="right"
        trigger={({ triggerRef }) => (
          <DisabledSelectedItemOverflow
            enabled={disabled?.isDisabled}
            message={disabled?.disabledMessage}
            tooltipProps={disabled?.disabledTooltipProps}
          >
            <div ref={triggerRef}>
              {renderItem(item, {
                isHovering,
                isSelected,
                isDisabled: !!disabled?.isDisabled,
              })}
            </div>
          </DisabledSelectedItemOverflow>
        )}
      >
        {!disabled?.isDisabled && (
          <div
            role="presentation"
            onClick={(e) => {
              // we want to prevent the click event from bubbling up to the menu
              e.stopPropagation();
            }}
          >
            {renderItemHover?.(item)}
          </div>
        )}
      </Popper>
    </div>
  );
}

function SelectPlaceholder({ children }: PropsWithChildren<unknown>): JSX.Element {
  return <span className="text-base text-muted-foreground">{children}</span>;
}

export function SimpleSelectValue({ children }: PropsWithChildren<unknown>): JSX.Element {
  return (
    <span className="w-[90%] overflow-hidden text-ellipsis text-nowrap break-words text-left text-base font-semibold text-foreground">
      {children}
    </span>
  );
}

interface SelectInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  renderInputToolbar?: BaseSelectorProps<unknown>['renderInputToolbar'];
  inputRef: RefObject<HTMLInputElement>;
  assistiveText?: string;
  hasBorder?: boolean;
  testId?: string;
  withAIStyling?: boolean;
  isCompact?: boolean;
}

export const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  ({ assistiveText, renderInputToolbar, inputRef, hasBorder, testId, withAIStyling, isCompact, ...props }, ref) => {
    const focusInput = (): void => {
      inputRef.current?.focus();
    };

    const toolbar = renderInputToolbar?.({ focusInput });
    const iconWrapperStyles = {
      minWidth: 16,
      minHeight: 16,
      display: 'flex',
    };

    return (
      <SelectInputContainerAsDiv data-testid={`${testId}-input-container`}>
        <div style={iconWrapperStyles}>
          <IconSearch2 size={16} />
        </div>

        <input
          className="box-border flex w-full flex-1 border-0 bg-background pl-2 text-foreground shadow-none outline-0 placeholder:text-muted-foreground focus:border-brand-400"
          ref={ref}
          {...props}
        />

        {assistiveText && <span className="mr-2 text-sm text-muted-foreground">{assistiveText}</span>}
        {toolbar ? <div className="ml-auto flex">{toolbar}</div> : null}
      </SelectInputContainerAsDiv>
    );
  },
);

interface SelectUnfocusedInputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isClearable?: boolean;
  onClear?: (e: SyntheticEvent) => void;
  clearButtonRef?: RefObject<HTMLAnchorElement>;
  testId?: string;
  withAIStyling?: boolean;
  isCompact?: boolean;
}

const SelectUnfocusedInput = forwardRef<HTMLButtonElement, SelectUnfocusedInputProps>(
  ({ children, isClearable, onClear, clearButtonRef, testId, withAIStyling, disabled, isCompact, ...props }, ref) => {
    return (
      <SelectInputContainerAsButton
        ref={ref}
        disabled={disabled}
        {...props}
        withAIStyling={withAIStyling}
        data-testid={`${testId}-select-input-container`}
        isCompact={isCompact}
      >
        {children}
        <div className="absolute right-4 top-[50%] flex translate-y-[-50%] items-center">
          {isClearable && (
            <IconButton
              type="close"
              ariaLabel="clear selection"
              onClick={onClear}
              innerRef={clearButtonRef}
              testId={`${testId}-clear-selection-button`}
            />
          )}
          <IconArrowDropDown color={disabled ? colors.steel[200] : undefined} />
        </div>
      </SelectInputContainerAsButton>
    );
  },
);

interface VisibilityHiddenProps {
  children: ReactNode;
  enabled?: boolean;
}

/**
 * This makes the element hidden when the `enabled` prop is false.
 * Downshift requires the input to always be on the screen in order to inject its props.
 */
export function VisibilityHidden({ children, enabled }: VisibilityHiddenProps): JSX.Element {
  return (
    <div
      style={
        enabled
          ? {
              visibility: 'hidden',
              display: 'none',
            }
          : undefined
      }
    >
      {children}
    </div>
  );
}

export function DefaultNoResultsDisplay(): JSX.Element {
  return <span className="flex items-center justify-center p-5 text-base font-medium text-foreground">No results</span>;
}

interface DisabledSelectedItemOverflowProps {
  enabled?: boolean;
  message?: string;
  tooltipProps?: DisabledProps['disabledTooltipProps'];
  children: JSX.Element;
}

function DisabledSelectedItemOverflow({
  children,
  enabled,
  message,
  tooltipProps,
}: DisabledSelectedItemOverflowProps): JSX.Element {
  if (!enabled) {
    return children;
  }
  return (
    <Tooltip message={message} {...tooltipProps}>
      <div className="relative cursor-not-allowed bg-steel-50 opacity-40">{children}</div>
    </Tooltip>
  );
}

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  isLoading?: boolean;
  isOpen?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  overflowRef?: RefObject<HTMLDivElement>;
}

const menuOverflowStyles = {
  maxHeight: 500,
  overflow: 'auto',
};

const menuStyles = { borderRadius: 4 };

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ isLoading, isOpen, children, header, footer, overflowRef, ...rest }, ref) => {
    return (
      <div ref={ref} style={menuStyles} {...rest}>
        {header}

        <div ref={overflowRef} style={menuOverflowStyles}>
          {isOpen && (
            <>
              {isLoading ? (
                <div className="flex justify-center p-5">
                  <CircleSpinnerInline />
                </div>
              ) : (
                children
              )}
            </>
          )}
        </div>

        {footer}
      </div>
    );
  },
);

function getSelectInputContainerClassName({
  hasBorder = true,
  withAIStyling,
  isCompact,
  hasToolbar,
  disabled,
}: {
  hasBorder?: boolean;
  withAIStyling?: boolean;
  isCompact?: boolean;
  hasToolbar?: boolean;
  disabled?: boolean;
}): string {
  return cn(
    `
  relative box-border flex h-10 w-full flex-1 cursor-pointer items-center
  bg-background pb-2 pl-4 pr-10 pt-2 text-foreground outline-0 focus:border-brand-400
  `,
    {
      'rounded-sm border': hasBorder,
      'border-b': !hasBorder,
      'h-8 pb-0 pl-3 pr-8 pt-0': isCompact,
      'pr-4': isNotNil(hasToolbar),
      'border-purple-500 bg-purple-100 focus:border-purple-500': withAIStyling,
      'disabled:cursor-not-allowed disabled:bg-steel-50': disabled,
    },
  );
}

function SelectInputContainerAsDiv({
  children,
  hasBorder,
  withAIStyling,
  isCompact,
  hasToolbar,
  disabled,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  disabled?: boolean;
  hasBorder?: boolean;
  withAIStyling?: boolean;
  isCompact?: boolean;
  hasToolbar?: boolean;
  ref?: RefObject<HTMLDivElement>;
}): JSX.Element {
  return (
    <div
      {...rest}
      className={getSelectInputContainerClassName({ hasBorder, withAIStyling, isCompact, hasToolbar, disabled })}
    >
      {children}
    </div>
  );
}

function SelectInputContainerAsButton({
  children,
  hasBorder,
  withAIStyling,
  isCompact,
  hasToolbar,
  disabled,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLButtonElement>> & {
  disabled?: boolean;
  hasBorder?: boolean;
  withAIStyling?: boolean;
  isCompact?: boolean;
  hasToolbar?: boolean;
  ref?: React.ForwardedRef<HTMLButtonElement>;
}): JSX.Element {
  return (
    <button
      {...rest}
      disabled={disabled}
      className={getSelectInputContainerClassName({ hasBorder, withAIStyling, isCompact, hasToolbar, disabled })}
      type="button"
    >
      {children}
    </button>
  );
}
